import {ConceptContent} from "@/globals/Types/ConceptContent";
import {ContentEnum} from "@/globals/ContentEnum";

const number: number = 4;
const name: string = 'HTML-Website';

export const concept4HTMLWebsite: ConceptContent = {
    heading: `${ContentEnum.CONCEPT} ${number}: ${name}`,
    image: require('@/assets/conceptpath/lesson1/lesson1concept4.jpg'),
    number,
    name,
    definition: [
        'A Website written in HTML',
        'HTML means: HyperText Markup Language',
        'It\'s the standard markup language to create and structure content on websites',
    ],
    reason: [
        {
            buzzword: 'Standardized',
            explanation: 'Standard maintained by the World Wide Web Consortium (W3C)',
        },
        {
            buzzword: 'Universally Supported',
            explanation: 'Supported by all web browsers',
        },
        {
            buzzword: 'Easy to Learn',
            explanation: 'Simple, clear and intuitive syntax',
        }
    ],
    sidenotes: [],
    references: [
        {
            title: 'Jon Duckett: HTML and CSS: Design and Build Websites',
            imageLink: require('@/assets/cover/cover_html_duckett.jpg'),
            weblinks: {
                US: 'https://amzn.to/3VvowLc',
                UK: 'https://amzn.to/3VxAFiR',
                DE: 'https://amzn.to/3VDXy4c',
                FR: 'https://amzn.to/4cujJ3u',
                JP: 'https://amzn.to/4cx34wb',
                CA: 'https://amzn.to/3VTgMny',
                IT: 'https://amzn.to/3VQYNOK',
                SP: 'https://amzn.to/3VSZDdD',
                AU: 'https://amzn.to/3xqDkCV',
                NL: 'https://amzn.to/3xloc9Y',
                SW: 'https://amzn.to/4cBCSRj',
                PL: 'https://amzn.to/3z861ow',
                BE: 'https://amzn.to/4ciOoRS',
            } 
        },
        {
            title: 'Mike Mcgrath: HTML in Easy Steps',
            imageLink: require('@/assets/cover/cover_html_mcgrath.jpg'),
            weblinks: {
                US: 'https://amzn.to/45zmq1B',
                UK: 'https://amzn.to/3zePcZg',
                DE: 'https://amzn.to/3VTYSkG',
                FR: 'https://amzn.to/45Js01l',
                JP: 'https://amzn.to/3VUPJsf',
                CA: 'https://amzn.to/3VSRHZU',
                IT: 'https://amzn.to/4eyQ2jG',
                SP: 'https://amzn.to/4ctdddg',
                AU: 'https://amzn.to/45ElpFh',
                NL: 'https://amzn.to/3xvh2Qo',
                SW: 'https://amzn.to/3KSKhzE',
                PL: 'https://amzn.to/3RFoKyf',
                BE: 'https://amzn.to/3XA72A7',
            }
        },
        {
            title: 'Elisabeth Robson, Eric Freeman: Head First HTML and CSS: A Learner\'s Guide to Creating Standards-Based Web Pages',
            imageLink: require('@/assets/cover/cover_html_robsen.jpg'),
            weblinks: {
                US: 'https://amzn.to/3VxARi5',
                UK: 'https://amzn.to/3VxAXGt',
                DE: 'https://amzn.to/4eA9cWb',
                FR: 'https://amzn.to/4eCiuB0',
                JP: 'https://amzn.to/3VyR9Hr',
                CA: 'https://amzn.to/4eL6i1c',
                IT: 'https://amzn.to/4cvxWgH',
                SP: 'https://amzn.to/3xCIYBN',
                AU: 'https://amzn.to/45AdvNm',
                NL: 'https://amzn.to/45EOOzu',
                SW: 'https://amzn.to/3zbcZJn',
                PL: 'https://amzn.to/3RDL3V0',
                BE: 'https://amzn.to/45Ect2E',
            }
        },
    ],
}