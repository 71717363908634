import {ITimezoneToCountry} from "@/globals/localization/ITimezoneToCountry";
import {CountryCodesEnum} from "@/globals/localization/CountryCodesEnum";

const timezoneToCountryUSA: ITimezoneToCountry = {
    'America/Adak': CountryCodesEnum.USA,
    'America/Anchorage': CountryCodesEnum.USA,
    'America/Boise': CountryCodesEnum.USA,
    'America/Chicago': CountryCodesEnum.USA,
    'America/Denver': CountryCodesEnum.USA,
    'America/Detroit': CountryCodesEnum.USA,
    'America/Indiana/Indianapolis': CountryCodesEnum.USA,
    'America/Indiana/Knox': CountryCodesEnum.USA,
    'America/Indiana/Marengo': CountryCodesEnum.USA,
    'America/Indiana/Petersburg': CountryCodesEnum.USA,
    'America/Indiana/Tell_City': CountryCodesEnum.USA,
    'America/Indiana/Vevay': CountryCodesEnum.USA,
    'America/Indiana/Vincennes': CountryCodesEnum.USA,
    'America/Indiana/Winamac': CountryCodesEnum.USA,
    'America/Juneau': CountryCodesEnum.USA,
    'America/Kentucky/Louisville': CountryCodesEnum.USA,
    'America/Kentucky/Monticello': CountryCodesEnum.USA,
    'America/Los_Angeles': CountryCodesEnum.USA,
    'America/Menominee': CountryCodesEnum.USA,
    'America/Metlakatla': CountryCodesEnum.USA,
    'America/New_York': CountryCodesEnum.USA,
    'America/Nome': CountryCodesEnum.USA,
    'America/North_Dakota/Beulah': CountryCodesEnum.USA,
    'America/North_Dakota/Center': CountryCodesEnum.USA,
    'America/North_Dakota/New_Salem': CountryCodesEnum.USA,
    'America/Phoenix': CountryCodesEnum.USA,
    'America/Sitka': CountryCodesEnum.USA,
    'America/Yakutat': CountryCodesEnum.USA,
    'Pacific/Honolulu': CountryCodesEnum.USA,
    'America/Atka': CountryCodesEnum.USA,
    'America/Fort_Wayne': CountryCodesEnum.USA,
    'America/Indianapolis': CountryCodesEnum.USA,
    'America/Knox_IN': CountryCodesEnum.USA,
    'America/Louisville': CountryCodesEnum.USA,
    'America/Shiprock': CountryCodesEnum.USA,
    'Navajo': CountryCodesEnum.USA,
    'US/Alaska': CountryCodesEnum.USA,
    'US/Aleutian': CountryCodesEnum.USA,
    'US/Arizona': CountryCodesEnum.USA,
    'US/Central': CountryCodesEnum.USA,
    'US/East-Indiana': CountryCodesEnum.USA,
    'US/Eastern': CountryCodesEnum.USA,
    'US/Hawaii': CountryCodesEnum.USA,
    'US/Indiana-Starke': CountryCodesEnum.USA,
    'US/Michigan': CountryCodesEnum.USA,
    'US/Mountain': CountryCodesEnum.USA,
    'US/Pacific': CountryCodesEnum.USA,
    'Pacific/Johnston': CountryCodesEnum.USA,
    //linked indirect
    'America/Antigua': CountryCodesEnum.USA,
    'Pacific/Samoa': CountryCodesEnum.USA,
    'US/Samoa': CountryCodesEnum.USA,
    'America/Barbados': CountryCodesEnum.USA,
    'America/Nassau': CountryCodesEnum.USA,
    'Pacific/Guam': CountryCodesEnum.USA,
    'Pacific/Saipan': CountryCodesEnum.USA,
    'America/Puerto_Rico': CountryCodesEnum.USA,
    'Pacific/Midway': CountryCodesEnum.USA,
    'Pacific/Wake': CountryCodesEnum.USA,
    'America/St_Thomas': CountryCodesEnum.USA,
    'America/Virgin': CountryCodesEnum.USA,
};

const timezoneToCountryUK: ITimezoneToCountry = {
    'Europe/London': CountryCodesEnum.UK,
    'GB': CountryCodesEnum.UK,
    'GB-Eire': CountryCodesEnum.UK,
    'Europe/Belfast': CountryCodesEnum.UK,
    //linked indirect
    'America/Anguilla': CountryCodesEnum.UK,
    'Atlantic/Bermuda': CountryCodesEnum.UK,
    'Pacific/Rarotonga': CountryCodesEnum.UK,
    'Europe/Prague': CountryCodesEnum.UK,
    'Europe/Tallinn': CountryCodesEnum.UK,
    'Pacific/Fiji': CountryCodesEnum.UK,
    'Atlantic/Stanley': CountryCodesEnum.UK,
    'Europe/Copenhagen': CountryCodesEnum.Sweden,
    'Atlantic/Faeroe': CountryCodesEnum.UK,
    'Atlantic/Faroe': CountryCodesEnum.UK,
    'Europe/Guernsey': CountryCodesEnum.UK,
    'America/Danmarkshavn': CountryCodesEnum.UK,
    'America/Godthab': CountryCodesEnum.UK,
    'America/Nuuk': CountryCodesEnum.UK,
    'America/Scoresbysund': CountryCodesEnum.UK,
    'America/Thule': CountryCodesEnum.UK,
    'Europe/Athens': CountryCodesEnum.UK,
    'Europe/Tirane': CountryCodesEnum.UK,
    'Europe/Mariehamn': CountryCodesEnum.UK,
    'Europe/Sarajevo': CountryCodesEnum.UK,
    'Europe/Sofia': CountryCodesEnum.UK,
    'Europe/Minsk': CountryCodesEnum.UK,
    'Europe/Nicosia': CountryCodesEnum.UK,
    'Europe/Helsinki': CountryCodesEnum.UK,
    'Atlantic/South_Georgia': CountryCodesEnum.UK,
    'Europe/Zagreb': CountryCodesEnum.UK,
    'Europe/Budapest': CountryCodesEnum.UK,
    'Eire': CountryCodesEnum.UK,
    'Europe/Dublin': CountryCodesEnum.UK,
    'Europe/Isle_of_Man': CountryCodesEnum.UK,
    'Atlantic/Reykjavik': CountryCodesEnum.UK,
    'Iceland': CountryCodesEnum.UK,
    'Europe/Jersey': CountryCodesEnum.UK,
    'America/Cayman': CountryCodesEnum.UK,
    'Europe/Vilnius': CountryCodesEnum.UK,
    'Europe/Riga': CountryCodesEnum.UK,
    'Europe/Chisinau': CountryCodesEnum.UK,
    'Europe/Tiraspol': CountryCodesEnum.UK,
    'Europe/Podgorica': CountryCodesEnum.UK,
    'Europe/Skopje': CountryCodesEnum.UK,
    'America/Montserrat': CountryCodesEnum.UK,
    'Europe/Malta': CountryCodesEnum.UK,
    'Europe/Oslo': CountryCodesEnum.UK,
    'Pacific/Niue': CountryCodesEnum.UK,
    'Pacific/Pitcairn': CountryCodesEnum.UK,
    'Atlantic/Azores': CountryCodesEnum.UK,
    'Atlantic/Madeira': CountryCodesEnum.UK,
    'Europe/Lisbon': CountryCodesEnum.UK,
    'Portugal': CountryCodesEnum.UK,
    'Europe/Bucharest': CountryCodesEnum.UK,
    'Europe/Belgrade': CountryCodesEnum.UK,
    'Atlantic/St_Helena': CountryCodesEnum.UK,
    'Europe/Ljubljana': CountryCodesEnum.UK,
    'Arctic/Longyearbyen': CountryCodesEnum.UK,
    'Atlantic/Jan_Mayen': CountryCodesEnum.UK,
    'Europe/Bratislava': CountryCodesEnum.UK,
    'America/Grand_Turk': CountryCodesEnum.UK,
    'Europe/Istanbul': CountryCodesEnum.UK,
    'Asia/Istanbul': CountryCodesEnum.UK,
    'Turkey': CountryCodesEnum.UK,
    'Europe/Kiev': CountryCodesEnum.UK,
    'Europe/Kyiv': CountryCodesEnum.UK,
    'Europe/Uzhgorod': CountryCodesEnum.UK,
    'Europe/Zaporozhye': CountryCodesEnum.UK,
    'America/Tortola': CountryCodesEnum.UK,
};

const timezoneToCountryGermany: ITimezoneToCountry = {
    'Europe/Berlin': CountryCodesEnum.Germany,
    'Europe/Busingen': CountryCodesEnum.Germany,
    //linked indirect
    'Europe/Vienna': CountryCodesEnum.Germany,
    'Europe/Zurich': CountryCodesEnum.Germany,
    'Europe/Vaduz': CountryCodesEnum.Germany,
};

const timezoneToCountryFrance: ITimezoneToCountry = {
    'Europe/Paris': CountryCodesEnum.France,
    //linked indirectly
    'America/St_Barthelemy': CountryCodesEnum.France,
    'America/Cayenne': CountryCodesEnum.France,
    'America/Guadeloupe': CountryCodesEnum.France,
    'Europe/Monaco': CountryCodesEnum.France,
    'America/Marigot': CountryCodesEnum.France,
    'America/Martinique': CountryCodesEnum.France,
    'Pacific/Noumea': CountryCodesEnum.France,
    'Pacific/Gambier': CountryCodesEnum.France,
    'Pacific/Marquesas': CountryCodesEnum.France,
    'Pacific/Tahiti': CountryCodesEnum.France,
    'America/Miquelon': CountryCodesEnum.France,
    'Pacific/Wallis': CountryCodesEnum.France,
};

const timezoneToCountryJapan: ITimezoneToCountry = {
    'Asia/Tokyo': CountryCodesEnum.Japan,
    'Japan': CountryCodesEnum.Japan,
};

const timezoneToCountryCanada: ITimezoneToCountry = {
    'America/Atikokan': CountryCodesEnum.Canada,
    'America/Blanc-Sablon': CountryCodesEnum.Canada,
    'America/Cambridge_Bay': CountryCodesEnum.Canada,
    'America/Coral_Harbour': CountryCodesEnum.Canada,
    'America/Creston': CountryCodesEnum.Canada,
    'America/Dawson': CountryCodesEnum.Canada,
    'America/Dawson_Creek': CountryCodesEnum.Canada,
    'America/Edmonton': CountryCodesEnum.Canada,
    'America/Fort_Nelson': CountryCodesEnum.Canada,
    'America/Glace_Bay': CountryCodesEnum.Canada,
    'America/Goose_Bay': CountryCodesEnum.Canada,
    'America/Halifax': CountryCodesEnum.Canada,
    'America/Inuvik': CountryCodesEnum.Canada,
    'America/Iqaluit': CountryCodesEnum.Canada,
    'America/Moncton': CountryCodesEnum.Canada,
    'America/Montreal': CountryCodesEnum.Canada,
    'America/Nipigon': CountryCodesEnum.Canada,
    'America/Pangnirtung': CountryCodesEnum.Canada,
    'America/Rainy_River': CountryCodesEnum.Canada,
    'America/Rankin_Inlet': CountryCodesEnum.Canada,
    'America/Regina': CountryCodesEnum.Canada,
    'America/Resolute': CountryCodesEnum.Canada,
    'America/St_Johns': CountryCodesEnum.Canada,
    'America/Swift_Current': CountryCodesEnum.Canada,
    'America/Thunder_Bay': CountryCodesEnum.Canada,
    'America/Toronto': CountryCodesEnum.Canada,
    'America/Vancouver': CountryCodesEnum.Canada,
    'America/Whitehorse': CountryCodesEnum.Canada,
    'America/Winnipeg': CountryCodesEnum.Canada,
    'America/Yellowknife': CountryCodesEnum.Canada,
    'Canada/Atlantic': CountryCodesEnum.Canada,
    'Canada/Central': CountryCodesEnum.Canada,
    'Canada/Eastern': CountryCodesEnum.Canada,
    'Canada/Mountain': CountryCodesEnum.Canada,
    'Canada/Newfoundland': CountryCodesEnum.Canada,
    'Canada/Pacific': CountryCodesEnum.Canada,
    'Canada/Saskatchewan': CountryCodesEnum.Canada,
    'Canada/Yukon': CountryCodesEnum.Canada,
};

const timezoneToCountryItaly: ITimezoneToCountry = {
    'Europe/Rome': CountryCodesEnum.Italy,
    //linked indirectly
    'Europe/San_Marino': CountryCodesEnum.Italy,
    'Europe/Vatican': CountryCodesEnum.Italy,
};

const timezoneToCountrySpain: ITimezoneToCountry = {
    'Africa/Ceuta': CountryCodesEnum.Spain,
    'Atlantic/Canary': CountryCodesEnum.Spain,
    'Europe/Madrid': CountryCodesEnum.Spain,
    //linked indirect
    'Europe/Andorra': CountryCodesEnum.Spain,
    'Europe/Gibraltar': CountryCodesEnum.Spain,
};

const timezoneToCountryAustralia: ITimezoneToCountry = {
    'Antarctica/Macquarie': CountryCodesEnum.Australia,
    'Australia/ACT': CountryCodesEnum.Australia,
    'Australia/Adelaide': CountryCodesEnum.Australia,
    'Australia/Brisbane': CountryCodesEnum.Australia,
    'Australia/Broken_Hill': CountryCodesEnum.Australia,
    'Australia/Canberra': CountryCodesEnum.Australia,
    'Australia/Currie': CountryCodesEnum.Australia,
    'Australia/Darwin': CountryCodesEnum.Australia,
    'Australia/Eucla': CountryCodesEnum.Australia,
    'Australia/Hobart': CountryCodesEnum.Australia,
    'Australia/LHI': CountryCodesEnum.Australia,
    'Australia/Lindeman': CountryCodesEnum.Australia,
    'Australia/Lord_Howe': CountryCodesEnum.Australia,
    'Australia/Melbourne': CountryCodesEnum.Australia,
    'Australia/North': CountryCodesEnum.Australia,
    'Australia/NSW': CountryCodesEnum.Australia,
    'Australia/Perth': CountryCodesEnum.Australia,
    'Australia/Queensland': CountryCodesEnum.Australia,
    'Australia/South': CountryCodesEnum.Australia,
    'Australia/Sydney': CountryCodesEnum.Australia,
    'Australia/Tasmania': CountryCodesEnum.Australia,
    'Australia/Victoria': CountryCodesEnum.Australia,
    'Australia/West': CountryCodesEnum.Australia,
    'Australia/Yancowinna': CountryCodesEnum.Australia,
    //linked indirectly
    'Indian/Cocos': CountryCodesEnum.Australia,
    'Indian/Christmas': CountryCodesEnum.Australia,
    'Pacific/Norfolk': CountryCodesEnum.Australia,
    'Pacific/Guadalcanal': CountryCodesEnum.Australia,
    'NZ': CountryCodesEnum.Australia,
    'NZ-CHAT': CountryCodesEnum.Australia,
    'Pacific/Auckland': CountryCodesEnum.Australia,
    'Pacific/Chatham': CountryCodesEnum.Australia,
    'Pacific/Fakaofo': CountryCodesEnum.Australia,
};

const timezoneToCountryNetherlands: ITimezoneToCountry = {
    'Europe/Amsterdam': CountryCodesEnum.Netherlands,
    //linked indirectly
    'America/Aruba': CountryCodesEnum.Netherlands,
    'America/Kralendijk': CountryCodesEnum.Netherlands,
    'America/Lower_Princes': CountryCodesEnum.Netherlands,
};

const timezoneToCountrySweden: ITimezoneToCountry = {
    'Europe/Stockholm': CountryCodesEnum.Sweden,
};

const timezoneToCountryPoland: ITimezoneToCountry = {
    'Europe/Warsaw': CountryCodesEnum.Poland,
    'Poland': CountryCodesEnum.Poland,
};

const timezoneToCountryBelgium: ITimezoneToCountry = {
    'Europe/Brussels': CountryCodesEnum.Belgium,
    //linked indirectly
    'Europe/Luxembourg': CountryCodesEnum.Belgium,
};

export const TimezoneToCountryMap: ITimezoneToCountry = {
    ...timezoneToCountryUSA,
    ...timezoneToCountryUK,
    ...timezoneToCountryGermany,
    ...timezoneToCountryFrance,
    ...timezoneToCountryJapan,
    ...timezoneToCountryCanada,
    ...timezoneToCountryItaly,
    ...timezoneToCountrySpain,
    ...timezoneToCountryAustralia,
    ...timezoneToCountryNetherlands,
    ...timezoneToCountrySweden,
    ...timezoneToCountryPoland,
    ...timezoneToCountryBelgium,
};